import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, Data } from '@angular/router';
import { map, pluck } from 'rxjs/operators';
import { FormGroup, FormBuilder } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { MenuService } from './menu.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-main-shell',
  templateUrl: './main-shell.component.html',
  styleUrls: ['./main-shell.component.scss'],
})
export class MainShellComponent implements OnInit, OnDestroy {
  searchForm: FormGroup;
  menuOpen$: Observable<boolean>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private menuService: MenuService
  ) {}

  ngOnInit() {
    this.initForm();
    this.initMenu();
    this.initRouteParams();
  }

  ngOnDestroy(): void {}

  initForm() {
    this.searchForm = this.fb.group({
      search: [''],
    });
  }

  initMenu() {
    this.menuOpen$ = this.menuService.mobileOpen;
  }

  initRouteParams() {
    this.route.queryParamMap
      .pipe(
        map((queryParamMap) => queryParamMap.get('q')),
        untilDestroyed(this)
      )
      .subscribe((search) => {
        this.searchForm.patchValue({
          search,
        });
      });
  }

  onSearch() {
    this.router.navigate(['/search/results'], {
      queryParams: { q: this.searchForm.get('search').value },
    });
  }

  onToggleMenu() {
    this.menuService.toggle();
  }
}

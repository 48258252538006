import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { User } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  @Input() user: User;
  @Input() @HostBinding('class.small') small = false;
  @Input() openable = true;
  @Input() active = false;

  constructor() {}

  ngOnInit() {}
}

import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit {
  navigationItems: NavigationItem[] = [
    {
      icon: "calendar",
      name: "Kalender",
      url: "calendar"
    }
    // {
    //   icon: 'operational-status',
    //   name: 'Driftsstatus',
    //   url: 'not-existing-url'
    // },
    // {
    //   icon: 'technical-services',
    //   name: `Tekniske tjenester`,
    //   url: 'not-existing-url'
    // },
    // {
    //   icon: "org-map",
    //   name: "Org. kart",
    //   url: "org-map"
    // }
    // {
    //   icon: 'view',
    //   name: 'View',
    //   url: 'not-existing-url'
    // },
    // {
    //   icon: 'menu',
    //   name: 'Meny',
    //   url: 'not-existing-url'
    // }
  ];

  constructor() {}

  ngOnInit() {}
}

interface NavigationItem {
  icon: string;
  name: string;
  url: string;
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { find, get, pick } from 'lodash';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { User } from '../shared/models/user';

const { endpoint } = environment;

@Injectable({
  providedIn: 'root',
})
export class UserBackendService {
  size: number = 25;
  constructor(private http: HttpClient) {}

  get(id: string): Observable<User> {
    return this.http
      .get<BackendUser>(`${endpoint}/contacts/id-${id}`)
      .pipe(map(mapUser), shareReplay(1));
  }

  current(): Observable<User> {
    return this.http
      .get<BackendUser>(`${endpoint}/auth/user`)
      .pipe(map(mapUser), shareReplay(1));
  }

  search(
    term: string,
    offset: number = 0,
    size: number = this.size
  ): Observable<Pick<User, 'email' | 'name'>[]> {
    if (!term) { return of([]); }

    const url = `${endpoint}/contacts/search?offset=${offset}&query=${encodeURIComponent(
      term.trim()
    )}`;
    return this.http.get<Pick<BackendUser, 'email' | 'name'>[]>(url);
  }
}

export const mapUser = (body: BackendUser) => ({
  id: null,
  name: body.name,
  jobTitle: body.jobTitle,
  phoneNumbers: body.phoneNumbers,
  email: body.email,
  address: get(body, 'addresses[0].value'),
  avatar: body.photoBase64
    ? 'data:image/jpeg;base64,' + body.photoBase64
    : null,
  coords: get(body, 'addresses[0].coords'),
  comments: body.comments,
  company: body.company,
  municipality: pick(body.municipality, ['name', 'logoUrl']),
  department: body.department,
  available: body.available,
  waveMunicipality: body.waveMunicipality,
  tasks: body.tasks,
  importNotes: body.importNotes,
  skills: body.skills,
  responsibilities: body.responsibilities,
  placement: body.placement,
  extraInfo: body.extraInfo,
  subdivision: body.subdivision
});

interface BackendUser {
  name: string;
  email: string;
  municipality: {
    name: string;
    logoUrl: string;
  };
  f;
  addresses?: {
    value: string;
    coords: {
      lat: number;
      lng: number;
    };
    type: string;
  }[];
  photoBase64?: string;
  jobTitle: string;
  comments: string;
  company: string;
  phoneNumbers?: { name: string; value: string }[];
  department?: string;
  available: boolean;
  waveMunicipality?: string;
  tasks?: string;
  importNotes?: string;
  skills?: string;
  responsibilities?: string;
  placement?: string;
  extraInfo?: string;
  subdivision?: string;
}

import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Inject
} from '@angular/core';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/platform-browser';

declare var H: any;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  private platform: any;
  @Input() latitude: number;
  @Input() longitude: number;
  @Input() height: string = '160px';
  @Input() width: string = '380px';
  @Input() zoom: number = 10;
  @ViewChild('map') public mapElement: ElementRef;
  map: any;
  public appId: string = environment.hereAppCredentials.id;
  public appCode: any = environment.hereAppCredentials.code;

  constructor(@Inject(DOCUMENT) private document: Document) {
    let useHTTPS = document.location.protocol.includes('https');
    this.platform = new H.service.Platform({
      app_id: this.appId,
      app_code: this.appCode,
      useHTTPS
    });
  }

  ngOnInit() {}

  public ngAfterViewInit() {
    let defaultLayers = this.platform.createDefaultLayers();
    this.map = new H.Map(
      this.mapElement.nativeElement,
      defaultLayers.normal.map,
      {
        zoom: this.zoom,
        center: { lat: this.latitude, lng: this.longitude },
        pixelRatio: window.devicePixelRatio || 1
      }
    );

    this.dropMarker({
      lat: this.latitude,
      lng: this.longitude
    });

    var behavior = new H.mapevents.Behavior(
      new H.mapevents.MapEvents(this.map)
    );
  }

  private dropMarker(coordinates: any) {
    let marker = new H.map.Marker(coordinates);
    this.map.addObject(marker);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CommunityLogoComponent } from './community-logo/community-logo.component';
import { DepartmentComponent } from './department/department.component';
import { HeaderSearchComponent } from './header/header-search/header-search.component';
import { HeaderComponent } from './header/header.component';
import { ItemInfoComponent } from './item-info/item-info.component';
import { ItemComponent } from './item/item.component';
import { MainContainerComponent } from './main-container/main-container.component';
import { MainShellComponent } from './main-shell/main-shell.component';
import { PoweredTextComponent } from './powered-text/powered-text.component';
import { ShellComponent } from './shell/shell.component';
import { UserDetailsPipe } from './user-details.pipe';
import { UserComponent } from './user/user.component';
import { VerticalConnectionComponent } from './vertical-connection/vertical-connection.component';
import { WhiteBoxComponent } from './white-box/white-box.component';
import { MapComponent } from './map/map.component';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  imports: [
    CommonModule,
    AngularSvgIconModule,
    RouterModule,
    ReactiveFormsModule,
  ],
  exports: [
    HeaderComponent,
    ShellComponent,
    PoweredTextComponent,
    CommunityLogoComponent,
    MainContainerComponent,
    MainShellComponent,
    WhiteBoxComponent,
    DepartmentComponent,
    UserComponent,
    VerticalConnectionComponent,
    UserDetailsPipe,
    MapComponent,
    FooterComponent,
  ],
  declarations: [
    HeaderComponent,
    ShellComponent,
    PoweredTextComponent,
    CommunityLogoComponent,
    MainContainerComponent,
    MainShellComponent,
    WhiteBoxComponent,
    HeaderSearchComponent,
    DepartmentComponent,
    ItemComponent,
    ItemInfoComponent,
    UserComponent,
    VerticalConnectionComponent,
    UserDetailsPipe,
    MapComponent,
    FooterComponent,
  ],
})
export class SharedModule {}

export { Department } from './models/department';
export { User } from './models/user';

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { TabsShellComponent } from './core/tabs-shell/tabs-shell.component';
import { MainShellComponent } from './shared/main-shell/main-shell.component';

const routes: Routes = [
  {
    path: '',
    component: MainShellComponent,
    children: [
      {
        path: 'search',
        loadChildren: './search/search.module#SearchModule',
      },
      {
        path: ':id',
        component: TabsShellComponent,
        children: [
          {
            path: '',
            loadChildren: './calendar/calendar.module#CalendarModule',
          },
          {
            path: 'org-map',
            loadChildren: './org-map/org-map.module#OrgMapModule',
          },
        ],
      },
      {
        path: '',
        redirectTo: '/search',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

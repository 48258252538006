import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { get } from 'lodash';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const { endpoint } = environment;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient, private router: Router) {}

  get municipality() {
    return localStorage.getItem('municipality');
  }

  private set _municipality(value) {
    localStorage.setItem('municipality', value);
  }

  login(email: string, password: string) {
    return this.http
      .get(`${endpoint}/auth/user`, {
        observe: 'response',
        headers: new HttpHeaders({
          authorization: `Basic ${btoa(email + ':' + password)}`
        })
      })
      .pipe(
        tap(res => (this._municipality = get(res, 'body.municipality.name'))),
        map(res => res.headers.get('X-Auth-Token')),
        tap(token => {
          this._token = token;
          this.router.navigate(['search']);
        })
      );
  }

  logout() {
    localStorage.removeItem('X-Auth-Token');
    this.router.navigate(['login']);
  }

  get authenticated(): boolean {
    return Boolean(this.token);
  }

  get token() {
    return localStorage.getItem('X-Auth-Token');
  }

  private set _token(value) {
    localStorage.setItem('X-Auth-Token', value);
  }
}

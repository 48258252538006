import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { get } from 'lodash';
import { Department } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.scss']
})
export class DepartmentComponent implements OnInit {
  @Input() department: Department;
  @Input() sub = false;
  @Input() @HostBinding('class.small') small = false;
  @Input() @HostBinding('class.openable') openable = false;
  @Output() departmentClick = new EventEmitter<Department>();

  constructor() {}

  ngOnInit() {}

  get font() {
    const length = get(this.department, 'name.length');
    if (length < 16) return 18;

    if (this.small) return 16 - length / 5;

    return 18 - length / 9;
  }
}

import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {
  @Input() @HostBinding('class.light') light = false;
  @Input() @HostBinding('class.narrow') narrow = false;
  @Input() @HostBinding('class.low') low = false;
  @Input() openable = false;
  @HostBinding('class.open') open = false;
  @Input() active = false;

  constructor() {}

  ngOnInit() {}
}

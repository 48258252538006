import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-community-logo',
  templateUrl: './community-logo.component.html',
  styleUrls: ['./community-logo.component.scss'],
})
export class CommunityLogoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

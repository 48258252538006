import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SharedModule } from '../shared/shared.module';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TabsShellComponent } from './tabs-shell/tabs-shell.component';

@NgModule({
  declarations: [TabsShellComponent, SidebarComponent],
  imports: [CommonModule, RouterModule, AngularSvgIconModule, SharedModule],
  exports: [TabsShellComponent],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}

import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() showMenuButton: boolean;
  @Input() menuOpen: boolean;
  @Output() toggleMenu = new EventEmitter<void>();
  @Output() search = new EventEmitter<void>();

  mobileMenuState: MobileMenuState = MobileMenuState.Closed;
  isMobileSearchHidden: boolean = true;
  isMobileMenuHidden: boolean = true;

  constructor() {}

  ngOnInit() {}

  toggleBurger() {
    if (this.mobileMenuState == MobileMenuState.Burger) {
      this.mobileMenuState = MobileMenuState.Closed;
    } else {
      this.mobileMenuState = MobileMenuState.Burger;
    }
  }

  toggleSearch() {
    if (this.mobileMenuState == MobileMenuState.Search) {
      this.mobileMenuState = MobileMenuState.Closed;
    } else {
      this.mobileMenuState = MobileMenuState.Search;
    }
  }

  get isSearchOpened() {
    return this.mobileMenuState == MobileMenuState.Search;
  }

  get isBurgerOpened() {
    return this.mobileMenuState == MobileMenuState.Burger;
  }
}

enum MobileMenuState {
  Closed,
  Search,
  Burger,
}

import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-item-info',
  templateUrl: './item-info.component.html',
  styleUrls: ['./item-info.component.scss'],
})
export class ItemInfoComponent implements OnInit {
  @Input() @HostBinding('class.light') light = false;
  @Input() email = '';
  @Input() userId = '';
  @Input() address = '';

  constructor() {}

  ngOnInit() {}
}

import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-powered-text',
  templateUrl: './powered-text.component.html',
  styleUrls: ['./powered-text.component.scss']
})
export class PoweredTextComponent implements OnInit {
  @Input()
  @HostBinding('style.color')
  color: string;

  @Input()
  small: boolean;

  @HostBinding('style.font-size') get fontSize() {
    return this.small ? '10px' : '';
  }

  @HostBinding('style.font-weight') get fontWeight() {
    return this.small ? '400' : '';
  }

  @HostBinding('style.bottom') get bottom() {
    return this.small ? '6px' : '';
  }

  constructor() {}

  ngOnInit() {}
}

import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (
      !this.auth.token ||
      !req.url.match(environment.endpoint) ||
      req.url.match('/auth/user')
    )
      return next.handle(req);

    const authReq = req.clone({
      setHeaders: { 'X-Auth-Token': this.auth.token }
    });

    return next
      .handle(authReq)
      .pipe(
        tap(
          null,
          error =>
            error instanceof HttpErrorResponse &&
            error.status === 401 &&
            this.router.navigate(['search'])
        )
      );
  }
}

import { Component, OnInit } from '@angular/core';
import { MenuService } from 'src/app/shared/main-shell/menu.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-tabs-shell',
  templateUrl: './tabs-shell.component.html',
  styleUrls: ['./tabs-shell.component.scss']
})
export class TabsShellComponent implements OnInit {
  mobileOpen$: Observable<boolean>;
  constructor(private menuService: MenuService) {}

  ngOnInit() {
    this.mobileOpen$ = this.menuService.mobileOpen;
  }
}

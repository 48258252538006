import { Pipe, PipeTransform } from '@angular/core';
import { UserBackendService } from '../search/user-backend.service';

@Pipe({
  name: 'userDetails',
})
export class UserDetailsPipe implements PipeTransform {
  constructor(private userService: UserBackendService) {}

  transform(id: string, args?: any): any {
    return this.userService.get(id);
  }
}

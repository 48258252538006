import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.scss']
})
export class MainContainerComponent implements OnInit {
  @Input()
  @HostBinding('style.width')
  width: string;

  constructor() {}

  ngOnInit() {}
}
